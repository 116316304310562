type BrandData = {
  okta_domain_details: {
    okta_domain: string;
    chameleon_client_id: string;
    default_redirect_url: string;
    scope: string;
  };
};

export const getOidcUrl = (brandData: BrandData, state: string) => {
  return (
    `${brandData.okta_domain_details.okta_domain}/oauth2/v1/authorize` +
    `?client_id=${atob(brandData.okta_domain_details.chameleon_client_id)}` +
    `&redirect_uri=${brandData.okta_domain_details.default_redirect_url}` +
    `&scope=${brandData.okta_domain_details.scope}` +
    `&response_type=id_token token` +
    `&response_mode=fragment` +
    `&state=${state}` +
    // FIXME: Look into what can be done here
    `&nonce=YsG76jo`
  );
};

export const validateIdToken = (idToken: string, brandData: any) => {
  try {
    const [, __payload] = idToken.split('.');
    const payload = JSON.parse(atob(__payload));

    // The aud (audience) claim should match the Client ID
    if (brandData.client_id.includes(btoa(payload.aud))) {
      // The exp (expiry time) claim has not already passed.
      if (new Date(payload.exp * 1000) > new Date()) {
        return true;
      }
    }

    return false;
  } catch (error) {
    return false;
  }
};
