import React from 'react';
import { getURLParamsFromLocation } from 'common/utils/location';
import { RouteComponentProps } from 'react-router-dom';
import Logger from 'common/utils/logger';

export const CallbackPage = React.memo((props: RouteComponentProps) => {
  const urlQueryParams = getURLParamsFromLocation(props.location);
  const destinationUrl = urlQueryParams.get('state');
  const error = urlQueryParams.get('error');
  const accessToken = urlQueryParams.get('access_token');
  const idToken = urlQueryParams.get('id_token');
  sessionStorage.setItem('accessToken', accessToken || '');
  sessionStorage.setItem('idToken', idToken || '');

  if (error) {
    props.history.push({
      pathname: '/error/',
      search: urlQueryParams.toString(),
    });
    return null;
  }

  if (destinationUrl) {
    urlQueryParams.delete('state');

    const separator = destinationUrl.includes('?') ? '&' : '?';
    // const finalDestination = `${destinationUrl + separator + urlQueryParams.toString()}`;

    Logger.debug('callback page redirection', { destination: destinationUrl });

    window.location.replace(destinationUrl);
  } else {
    window.location.replace('/');
  }

  return null;
});
